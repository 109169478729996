<template>
  <div>
    <div class="repeatable-container ml-1 mr-1">
      <div class="heading bold">
        <span>
          {{ templateData.name + " " + index }}
        </span>
        <el-link type="danger" @click="removeData" :underline="false" v-if="!getIsView">
          <i class="el-icon-delete"></i>
        </el-link>
      </div>
      <div class="fields-container d-flex">
        <el-scrollbar
          wrap-style="mix-width: 900px !important; padding-bottom: 18px;"
        >
          <div v-if="showData" class="d-flex flex-wrap" style="gap: 10px">
            <div
              v-for="(column, i) in fieldsData.filter(
                (e) =>
                  e.input_type !== 'DATA_TABLE' &&
                  e.input_type !== 'AUTO_INCREMENT_NUMBER'
              )"
              :key="'repeatable' + i"
              class="flex-basis-25"
              :style="{
                'min-width': '225px',
                'max-width':
                  column.input_type === 'RADIO_BUTTON_GROUP' ||
                  column.input_type === 'CHECKBOX_GROUP' ||
                  column.input_type === 'PHONE_COUNTRY_CODE'
                    ? '460px'
                    : '200px',
              }"
            >
              <component
                v-if="!checkhideFields(column)"
                v-bind:is="column.components.execute"
                :data="column"
                :form="form"
                :fieldsData="fieldsData"
                :hasLabel="true"
                :is-view="getIsView"
                @applyFormRules="rulesEventEmitter"
                :checkIsDisabled="checkIsDisabled(column)"
                @entityDataUpdated="setDataToEntityVariables"
                :authenticatedUserInfoFromFormbuilder="authenticatedUserInfoFromFormbuilder"
                :isFromFormbuilderRepeatableTemplate="checkIsFirstRowStandardTemplate"
              ></component>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import { dataTableAllowedExcecuteFields } from "../templates/formComponentsExecute/index";
import TemplateBuilderHelper from "@/mixins/templateBuilderHelper.js";
var fieldsList = require("../templates/fields.json");
export default {
  props: [
    "template",
    "templateData",
    "isExecute",
    "entityDataId",
    "layout",
    "currentStep",
    "currentEntity",
    "entityData",
    "isEdit",
    //  "isApplicationUserSide",
    "currentMenu",
    "currentStepIndex",
    "formbuilderDataId",
    "formbuilderId",
    "formbuilderDetails",
    "currentFormbuilderData",
    "form",
    "index",
    "authenticatedUserInfoFromFormbuilder",
  ],
  components: {
    ...dataTableAllowedExcecuteFields,
  },
  mixins: [TemplateBuilderHelper],
  data() {
    return {
      loading: false,
      field: [],
      disabledFields: [],
      hideFields: [],
      colorFields: {},
      fieldsData: [],
      fieldActions: [],
      fieldComponentsMap: {},
      showData: false,
      isFromFormbuilderRepeatableTemplate: false,
    };
  },
  mounted() {
    if (fieldsList) {
      this.fieldActions = [
        ...fieldsList.allFormFields,
        ...fieldsList.allContentFields,
        ...fieldsList.allAdvancedFields,
      ];
    }
    if (this.fieldActions) {
      this.fieldActions.forEach((field) => {
        if (!this.fieldComponentsMap[field.inputType]) {
          this.fieldComponentsMap[field.inputType] = field.components;
        }
      });
    }
    this.setTemplateInfo(this.templateData);
  },
  computed: {
    checkIsFirstRowStandardTemplate() {
      return this.templateData?.type == 'STANDARD' && this.index == 1  ? true : false;
    },
    getIsView() {
      if (this.$route?.name === 'GuestFormbuilderView') {
        return true;
      }
      return false;
    },
  },
  methods: {
    removeData() {
      this.$emit("removeRowData", this.index);
    },
    async setDataToEntityVariables(data, parent, label, changed) {
      if (label) {
        this.$set(this.form, parent.key + "/name", label);
      }
      this.fieldsData.forEach((field) => {
        if (
          field.relationship_key == parent.key &&
          data?.entityData &&
          (field.field_assignable == "read_only" ||
            field.inputType == "ENTITY" ||
            !this.form[field.key] ||
            changed)
        ) {
          if (
            field.global_variable_entity_field.includes("~") ||
            field.variable_action === "RESOURCE_AVERAGE"
          ) {
            // we need to skip assign data part we if the field is relationship field
            // becase we are setting entity data. but entity data don't have relation data by default. it makes value as null.
            return;
          }
          if (field.global_variable_entity_field) {
            let templateId = "";
            let key = "";
            let value = "";
            let valueName = "";
            if (field.global_variable_entity_field.includes("#")) {
              templateId = field.global_variable_entity_field.split("#")[0];
              key = field.global_variable_entity_field.split("#")[1];
            } else {
              templateId = field.global_variable_entity_field;
            }

            if (
              data.entityData &&
              data.entityData[templateId] &&
              data.entityData[templateId][key]
            ) {
              value = data.entityData[templateId][key];

              if (field.inputType == "ENTITY") {
                valueName = data.entityData[templateId][key + "/name"];
                this.$set(this.form, field.key + "/default", [value]);
              }
            }
            if (this.form[field.key]) {
              if (data.entityData[templateId][key + "_code"]) {
                this.form[field.key + "_code"] =
                  data.entityData[templateId][key + "_code"];
              }
              this.form[field.key] = value;
              if (valueName) {
                this.form[field.key + "/name"] = valueName;
              }
            } else {
              this.$set(this.form, field.key, value);
              if (valueName) {
                this.$set(this.form, field.key + "/name", valueName);
              }
            }
          }
        }
      });
      // if (!this.form.parent_data) {
      //   this.form.parent_data = {};
      // }
      // if (entityId) {
      //   this.form.parent_data[entityId] = data;
      // } else {
      //   this.form.parent_data = {};
      // }
      this.rulesEventEmitter();
    },
    checkIsDisabled(field) {
      return this.disabledFields.find((e) => e == field.key) ? true : false;
    },
    checkhideFields(field) {
      return this.hideFields.find((e) => e == field.key) ? true : false;
    },
    rulesEventEmitter() {
      this.applyRulesOnFields(this.fieldsData, this.templateRules, this.form);
    },
    async unsetEntityVariables(parent) {
      this.fieldsData.forEach((field) => {
        if (field.relationship_key == parent.key) {
          this.$set(this.form, field.key, "");
        }
      });
      this.rulesEventEmitter();
    },
    async setTemplateInfo(templateData) {
      this.loading = true;
      if (this.templateData?.sections[0]?.fields) {
        let defaultSection =
          templateData &&
          templateData.sections.find((x) => x.type == "DEFAULT");
        defaultSection.fields = defaultSection.fields.sort((a, b) => {
          return a.y - b.y;
        });
        this.fieldsData = this.setSizeAndPositionForElements(
          defaultSection.fields
        );
      }
      if (
        templateData &&
        templateData.sections &&
        templateData.sections[0] &&
        templateData.sections[0].checkBoxGroup
      ) {
        this.singleCompanyTemplateCheckBoxArray =
          templateData.sections[0].checkBoxGroup;
      }
      this.fieldsData;
      if (this.form.first_name && this.form.last_name && !this.form.name) {
        this.form.name = this.form.first_name + ' ' + this.form.last_name;
      }
      this.templateRules = templateData.rules ? [...templateData.rules] : [];
      this.applyRulesOnFields(this.fieldsData, this.templateRules, this.form);
      setTimeout(() => {
        this.showData = true;
      }, 100);
      this.loading = false;
    },
    setSizeAndPositionForElements(elements) {
      let questionIndex = 1;
      return elements.map((field) => {
        field = {
          ...field,
        };
        if (field.type == "FIELD") {
          field.questionIndex = questionIndex;
          questionIndex = questionIndex + 1;
        }
        if (this.getEntityId) {
          field.parent_entityId = this.getEntityId;
          field.parent_entityDataId = this.entityDataId;
        }
        if (field.input_type === "DATA_TABLE") {
          field.data_table_columns = (field.data_table_columns || []).map(
            (e) => {
              e.parent_entityId = this.getEntityId;
              e.parent_entityDataId = this.entityDataId;
              if (this.templateData?._id) {
                e.template_id = this.templateData._id;
              }
              return e;
            }
          );
        }
        if (this.templateData?._id && !field.template_id) {
          field.template_id = this.templateData._id;
        }
        field["components"] = this.fieldComponentsMap[field.inputType];
        return field;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.repeatable-container {
  border: 1px dotted #e6e6e6;
  .fields-container {
    padding: 25px;
  }
  .heading {
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #f2f6fc;
    font-size: 14px;
    font-weight: 500;
    color: #030503;
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"repeatable-container ml-1 mr-1"},[_c('div',{staticClass:"heading bold"},[_c('span',[_vm._v(" "+_vm._s(_vm.templateData.name + " " + _vm.index)+" ")]),(!_vm.getIsView)?_c('el-link',{attrs:{"type":"danger","underline":false},on:{"click":_vm.removeData}},[_c('i',{staticClass:"el-icon-delete"})]):_vm._e()],1),_c('div',{staticClass:"fields-container d-flex"},[_c('el-scrollbar',{attrs:{"wrap-style":"mix-width: 900px !important; padding-bottom: 18px;"}},[(_vm.showData)?_c('div',{staticClass:"d-flex flex-wrap",staticStyle:{"gap":"10px"}},_vm._l((_vm.fieldsData.filter(
              (e) =>
                e.input_type !== 'DATA_TABLE' &&
                e.input_type !== 'AUTO_INCREMENT_NUMBER'
            )),function(column,i){return _c('div',{key:'repeatable' + i,staticClass:"flex-basis-25",style:({
              'min-width': '225px',
              'max-width':
                column.input_type === 'RADIO_BUTTON_GROUP' ||
                column.input_type === 'CHECKBOX_GROUP' ||
                column.input_type === 'PHONE_COUNTRY_CODE'
                  ? '460px'
                  : '200px',
            })},[(!_vm.checkhideFields(column))?_c(column.components.execute,{tag:"component",attrs:{"data":column,"form":_vm.form,"fieldsData":_vm.fieldsData,"hasLabel":true,"is-view":_vm.getIsView,"checkIsDisabled":_vm.checkIsDisabled(column),"authenticatedUserInfoFromFormbuilder":_vm.authenticatedUserInfoFromFormbuilder,"isFromFormbuilderRepeatableTemplate":_vm.checkIsFirstRowStandardTemplate},on:{"applyFormRules":_vm.rulesEventEmitter,"entityDataUpdated":_vm.setDataToEntityVariables}}):_vm._e()],1)}),0):_vm._e()])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }